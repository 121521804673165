import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./Styles.css";
import { AddShoppingCart, Favorite, FavoriteBorder } from "@mui/icons-material";

function ProductCategory() {
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const { categoryId } = useParams();
  const ProductsData = require("./products.json");

  useEffect(() => {
    const fetchData = () => {
      try {
        const category = ProductsData.find(
          (cat) => cat.categoryid === categoryId
        );
        setProducts(category.products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  });

  // const handleMouse = (product) => {
  //   if (show === false) {
  //     product = setShow(true);
  //   } else {
  //     product = setShow(false);
  //   }
  // };

  // const handleLikeClick = (productId) => {
  //   const updatedProducts = category.map((product) => {
  //     if (product.id === productId) {
  //       return { ...product, liked: !product.liked };
  //     }
  //     return product;
  //   });
  //   setProducts(updatedProducts);
  // };

  const heartFavoriteHandler = (e) => {
    // e.preventDefault();
    // const productId = parseInt(e.target.attributes["data-sessionid"].value);

    console.log("Test", e);
    // setProducts(
    //   products.map((item) => {
    //     if (item.id === productId) {
    //       setShow(true);
    //       return { ...item, like: true };
    //     }
    //     setShow(false);
    //     return item;
    //   })
    // );
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements at indices i and j
    }
    return array;
  }

  const shuffledProducts = shuffleArray(products);

  return (
    <div className="products-container-category">
      {shuffledProducts.map((product, index) => (
        <div className="product-card" key={index}>
          <div className="favoriteContainer">
            <div className="favouriteImage">
              <Link
                to={`/product/${product.productId}`}
                key={product.productId}
              >
                <img src={product.productImage} alt={product.productname} />{" "}
              </Link>
            </div>
            <div className="favorite">
              <div
                data-sessionid={product.productId}
                onClick={(e) => heartFavoriteHandler(e)}
              >
                {show ? (
                  <Favorite className="loveHearts" />
                ) : (
                  <FavoriteBorder className="loveHearts" />
                )}
              </div>
            </div>
          </div>
          <div className="product-info">
            <h3>{product.productname}</h3>
            <p>
              {product.productdescription.substring(0, 30)}
              {"->"}
            </p>
          </div>
          <div className="addcart">
            <div className="addcartSection">
              <button className="remove-to-cart-btn">-</button>
              <input placeholder="0" name="quantity" />
              <button className="add-to-cart-btn">+</button>
            </div>
            <div>
              <span>Add to Cart</span>
              <AddShoppingCart
                style={{ marginLeft: "1rem", color: "var(--blendGreenColor)" }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductCategory;
