import React from "react";
import { Routes, Route } from "react-router-dom";
import Nomatch from "./nomatch";
import Home from "./home";
import Layout from "./components/Layout/Layout";
import ProductCategory from "./components/products/ProductCategory";
import ProductDetail from "./components/products/ProductDetail";
import Resources from "./components/pitchdeck/pitchdeck";
import AboutUs from "./components/generalpages/aboutus";
import ContactUs from "./components/generalpages/contactus";

function AppRouter() {
  return (
    <>
      <Routes>
        {/* Layout1 */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/category/:categoryId"
            element={<ProductCategory />}
          ></Route>
          <Route path="/product/:productId" element={<ProductDetail />}></Route>
          <Route path="/resources" element={<Resources />} />
          <Route path="/About Us" element={<AboutUs />} />
          <Route path="/Contact Us" element={<ContactUs />} />
          <Route path="*" element={<Nomatch />} />
        </Route>
      </Routes>
    </>
  );
}

export default AppRouter;
