import React from "react";
import Styles from "./Styles.module.css";

function AboutUs() {
  return (
    <div className={Styles.productsContainer}>
      <div className={Styles.productCard}>
        <div className={Styles.subContainer}>
          <h3>Background</h3>
          <p>
            ShamiriVeg is a digital platform dedicated to empowering small-scale
            grocery traders to manage and grow their businesses efficiently. Our
            platform offers a comprehensive suite of tools and features,
            including{" "}
          </p>
          <img src="/aboutus/offerings.png" alt="ShamiriVeg" />
          <p>
            By leveraging cutting-edge technology and tailored solutions,
            ShamiriVeg enables traders to streamline operations, increase sales,
            and achieve sustainable growth in an increasingly competitive market
            landscape.
          </p>
          <h3>Mission</h3>
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "25%" }}
              src="/aboutus/mission.png"
              alt="ShamiriVeg"
            />
            <p>
              Our mission at ShamiriVeg is to democratize access to advanced
              technology and resources, leveling the playing field for
              small-scale grocery traders and empowering them to thrive in the
              digital age.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className={Styles.productCard}>
        <div className={Styles.subContainer}>
          <img src="/farmers/products.jpg" alt="ShamiriVeg" />
          <p>
            We believe that every trader, regardless of size or resources,
            deserves the opportunity to succeed and make a meaningful impact in
            their communities.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
