import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.navcontainer}>
      <div className={Styles.rightSideA}>
        <Link to="/">
          <img src="/aboutus/logotext.png" alt="SHAMIRIVEG" />
        </Link>
      </div>
      <div className={Styles.rightSideB}>
        <Zoom>
          <Link to="/">
            <img src="/aboutus/discountbanner.png" alt="SHAMIRIVEG" />
          </Link>
        </Zoom>
      </div>
    </div>
  );
}

export default MainMenu;
