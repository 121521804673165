import React from "react";

export default function NoMatch() {
  return (
    <div
      style={{
        margin: "auto",
        width: "50%",
        padding: "20px",
      }}
    >
      <div style={{margin: "auto",width:"50%"}}>
        <div>
          <img src="/aboutus/Shamiriveg_logo_high_res.png" alt="ShamiriVeg" />
        </div>
        <div>
          <img src="/aboutus/empower_grow_succeed.png" alt="ShamiriVeg" />
        </div>
      </div>
    </div>
  );
}
