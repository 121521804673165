import React from "react";
import Styles from "./Styles.module.css";
import { Agriculture } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";

const Farmers = () => {
  return (
    <div className={Styles.productsContainer}>
      <div className={Styles.productCard}>
        <div className={Styles.subContainer}>
          <Zoom>
            <h1>Linking Small Scale Grocery Traders to Customers</h1>
          </Zoom>
          <p>
            At ShamiriVeg, we redefine the management and growth of small-scale
            grocery businesses. Our suite of AI-driven tools and market
            analytics enhances productivity, streamlines operations, and
            increases sales, enabling traders to scale their businesses
            efficiently. Through innovative digital marketing strategies, we
            seamlessly connect traders with their customers, accessible from
            their mobile devices. Committed to reshaping the future of grocery
            shopping in Kenya, we empower traders in the agribusiness sector
            with digital tools for success and prosperity.
          </p>
          <p>Our Platforms Provides:</p>
          <div className={Styles.listing}>
            {data.map((item, index) => (
              <Link to={"/"} key={index}>
                <li>
                  <Agriculture
                    style={{
                      paddingRight: "2rem",
                      color: "var(--blendGreenColor)",
                    }}
                  />
                  <span style={{ fontWeight: "bold" }}>{item.feature} </span>: -{" "}
                  {"\t\t "} {item.item}
                </li>
              </Link>
            ))}
          </div>{" "}
          <button className={Styles.infobtn}>Talk to us Today</button>
        </div>
      </div>
      <div className={Styles.productCard}>
        <img src="/farmers/products.jpg" alt="ShamiriVeg" />
      </div>
    </div>
  );
};

export default Farmers;

const data = [
  {
    feature: "Storefront Shop (E-commerce) ",
    item: "Storefronts for customers, driving sales and expanding their reach in the digital marketplace",
  },
  {
    feature: "Delivery App ",
    item: ":  Intergrated with the Storefront Shops to efficiently manage and process customer orders for doorstep delivery ",
  },
  {
    feature: "Inventory Management",
    item: "Inventory tracking, stock alerts, and product categorization, optimize stock levels, minimize wastage, and  meet customer demand"
  },
  {
    feature: "Customer Engagement Tools",
    item: "Provides a suite of customer engagement tools, including reviews, chats, complaints, and feedback functionalities.",
  },
  {
    feature: "Finance Backend for Traders",
    item: "Tracking sales revenue and expenses to generating financial reports and forecasts, make informed decisions and manage finances effectively",
  },
  {
    feature: "Market Analytics for Traders",
    item: "Equips traders with valuable insights and data-driven intelligence to make informed business decisions",
  },
];
