// NavBar.js
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import navLinks from "./NavBarData";
import "./NavBar.css";
import { ShoppingCart, ArrowDropDown } from "@mui/icons-material";

const MenuLinks = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const TitleLink = ({ link }) => {
    return (
      <div style={{ display: "flex" }}>
        {link}{" "}
        <div style={{ marginTop: "0.2rem" }}>
          <ArrowDropDown />
        </div>
      </div>
    );
  };

  return (
    <nav className="navbar">
      <div className="menu-icon" onMouseEnter={toggleMenu}>
        <FaBars />
      </div>
      <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
        {navLinks.map((link, index) => (
          <li key={index} onMouseLeave={closeMenu}>
            <Link to={link.path}>
              {link.subLinks ? <TitleLink link={link.title} /> : link.title}
            </Link>
            {link.subLinks && (
              <ul className="sub-links">
                {link.subLinks.map((subLink, subIndex) => (
                  <li key={subIndex}>
                    <Link to={subLink.path}>{subLink.subtitle}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      <div className="nonlinks">
        <input placeholder="Search" />
        <p>Login</p>
        <Link to={"/"}>
          <ShoppingCart sx={{ color: "#FC0" }} />{" "}
        </Link>
      </div>
    </nav>
  );
};

export default MenuLinks;
