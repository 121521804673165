import React, { useState, useEffect } from "react";
import Slider from "./components/slider/Slider";
import Products from "./components/products/Products";
import "./Styles.css";
import AdvertBanner from "./components/home/advertbanner";
import RecipeBlogAd from "./components/products/recipeblogAd";
import Fruits from "./components/products/fruits";
import Farmers from "./components/farmers/farmers";
export default function Home() {
  const [products, setProducts] = useState([]);
  const ProductsData = require("./components/products/products.json");

  useEffect(() => {
    const fetchData = () => {
      try {
        setProducts(ProductsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, [ProductsData]);

  return (
    <div className="main">
      <div>
        <Slider />
      </div>
      <div style={{ textAlign: "center", color: "var(--greyCardTop)" }}>
        <h1>
          Nairobi Fresh{" "}
          <span style={{ color: "var(--primaryColor)" }}>Grocery</span> Market
          Place
        </h1>
      </div>
      <AdvertBanner />
      <div>
        <div className="showcase">
          <Products ProductsData={products} />
          <Fruits />
        </div>
        <Farmers />
        <RecipeBlogAd />
      </div>
    </div>
  );
}
