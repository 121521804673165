// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
 
  {
    title: "Fruits",
    path: "/category/1",
  },
  {
    title: "Vegetables",
    path: "/category/2",
  },
  {
    title: "Animal Produce",
    path: "/category/3",
  },
  {
    title: "Grains",
    path: "/category/4",
  },
];

export default navLinks;
