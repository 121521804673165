import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Styles from "./StylesC.module.css";
import Fruits from "../products/fruits";

function ProductDetail() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const ProductsData = require("./products.json");
  useEffect(() => {
    // Fetch product details using productId
    // Here you should have a function to fetch product details from your API or data source
    // For demonstration purposes, I'll just find the product from the dummy data
    const foundProduct = ProductsData.flatMap(
      (category) => category.products
    ).find((prod) => prod.productId === productId);
    setProduct(foundProduct);
  }, [productId, ProductsData]);

  if (!product) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  // Product detail rendering
  return (
    <div className={Styles.main}>
      <div className={Styles.productsContainer}>
        <div className={Styles.productCard}>
          <div className={Styles.productDetailContainer}>
            <div className={Styles.productImage}>
              <img src={product.productImage} alt={product.productname} />
              {/* Add thumbnails */}
            </div>
          </div>
        </div>
        <div className={Styles.productCard}>
          <div className={Styles.productDetails}>
            <h2>{product.productname}</h2>
            <p>
              <span>ID:</span> {product.productId}
            </p>
            <p>
              <span>Product Name:</span>
              {product.productlocalname ? product.productlocalname : ""}
            </p>
            <p>
              {" "}
              <span>Product Description:</span> {product.productdescription}
            </p>
            <p>
              <span>Product Origin:</span> {"\t\t"} {product.productorigin}
            </p>
            <p>
              <span>Product Price: </span> {"\t\t"}
              <span
                style={{ fontSize: "large", color: "var(--blendGreenColor)" }}
              >
                Shs {product.productPrice}/-
              </span>
            </p>
            <p>
              <span>Product Weight:</span> {"\t\t"} ({product.productweight})
            </p>
            <p>
              <span>Product Packaging:</span> {"\t\t"} ({product.ordertype})
            </p>
            <p>
              <span>Order Type:</span> {"\t\t"} ({product.packaging})
            </p>
          </div>
          <button className={Styles.infobtn}>Add to Cart</button>
        </div>
      </div>
      <h3 style={{ padding: "0rem 2rem" }}>Other Related products</h3>
      <div className={Styles.otherProducts}>
        <Fruits />
      </div>
    </div>
  );
}

export default ProductDetail;
