const SliderData = [
  {
    id: 1,
    image: "/images/slider1.jpg",
    alt: "ShamiriVeg 1",
  },
  {
    id: 2,
    image: "/images/slider2.jpg",
    alt: "ShamiriVeg 2",
  },
  {
    id: 3,
    image: "/images/slider3.jpg",
    alt: "ShamiriVeg 3",
  },

];

export default SliderData;
