import React from "react";
import { Outlet } from "react-router-dom";

// import Footer from "../footer/";
import NavBar from "../navbar/navbar";

import Footer from "../footer/footer";
import "./Styles.css"

function Layout() {
  //   ScrollToTop();

  return (
    <div className="container">
      <div>
        <NavBar />
      </div>
      <div>
        <Outlet />
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: "smaller",
            background: "var(--primaryColor)",
            padding: "0.15rem",
          }}
        >
          <hr />
          <p> Copyright : &copy; {new Date().getFullYear()} ShamiriVeg</p>
        </div>
      </div>
    </div>
  );
}

export default Layout;
