import AppRouter from "./approuter";
import { Content } from "./components/hooks/scrollbuttonstyles";
import ScrollButton from "./components/hooks/scrollbutton";

function App() {
  return (
    <div>
      <AppRouter />
      <Content />
      <ScrollButton />
    </div>
  );
}

export default App;
