import React from "react";
import ContactPage from "./details/contactpage";

function ContactUs() {
  return (
    <div>
      <ContactPage/>
    </div>
  );
}

export default ContactUs;
