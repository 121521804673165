import React from "react";
import Styles from "./styles.module.css";
import ContactForm from "../../hooks/contact";
import { Zoom } from "react-awesome-reveal";

function ContactPage() {
  return (
    <div className={Styles.container}>
      <div className={Styles.subcontainer}>
        <div className={Styles.header}>
          <h1>Get intouch</h1>
          <Zoom>
            <img src="/aboutus/discountbanner.png" alt="John Makola" />
          </Zoom>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
