import React, { useState, useEffect } from "react";
import "./Styles.css";
import { Link } from "react-router-dom";
import { AddShoppingCart, Favorite, FavoriteBorder } from "@mui/icons-material";

function Fruits() {
  const [show, setShow] = useState(false);
  const [fruits, setFruits] = useState([]);
  const ProductsData = require("./products.json");

  useEffect(() => {
    const fetchData = () => {
      try {
        const category = ProductsData.find((cat) => cat.categoryid === "1");
        setFruits(category.products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchData();
  }, []);

  console.log(
    "Uganda",
    fruits.map((i) => i)
  );

  const heartFavoriteHandler = (e) => {
    // e.preventDefault();
    // const productId = parseInt(e.target.attributes["data-sessionid"].value);

    console.log("Test", e);
    // setProducts(
    //   products.map((item) => {
    //     if (item.id === productId) {
    //       setShow(true);
    //       return { ...item, like: true };
    //     }
    //     setShow(false);
    //     return item;
    //   })
    // );
  };

  function generateNumbersInRange() {
    const x = Math.floor(Math.random() * fruits.length) + 1; 
    const y = x + 4;
    return [x, y];
  }  
  const [x,y]= generateNumbersInRange()


  return (
    <div className="homeProductsShowcase">
      <div>
        <h1>
          Fresh <span style={{ color: "var(--blendGreenColor)" }}> Fruits & Juices</span>{" "}
          available
        </h1>
      </div>

      <div className="product-container">
        {fruits.slice(x, y).map((product, index) => (
          <div className="product-card" key={index}>
            <div className="favoriteContainer">
              <div className="favouriteImage">
                <Link
                  to={`/product/${product.productId}`}
                  key={product.productId}
                >
                  <img src={product.productImage} alt={product.productname} />{" "}
                </Link>
              </div>
              <div className="favorite">
                <div
                  data-sessionid={product.productId}
                  onClick={(e) => heartFavoriteHandler(e)}
                >
                  {show ? (
                    <Favorite className="loveHearts" />
                  ) : (
                    <FavoriteBorder className="loveHearts" />
                  )}
                </div>
              </div>
            </div>
            <div className="product-info">
              <h3>{product.productname}</h3>
              <p>
                {product.productdescription.substring(0, 30)}
                {"->"}
              </p>
            </div>
            <div className="addcart">
              <div className="addcartSection">
                <button className="remove-to-cart-btn">-</button>
                <input placeholder="0" name="quantity" />
                <button className="add-to-cart-btn">+</button>
              </div>
              <div>
                <span>Add to Cart</span>
                <AddShoppingCart
                  style={{
                    marginLeft: "1rem",
                    color: "var(--blendGreenColor)",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Fruits;
