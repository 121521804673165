import React from "react";

function Resources() {
  return (
    <div>
      <img src="/aboutus/Shamiriveg_logo_high_res.png" alt="ShamiriVeg" />
    </div>
  );
}

export default Resources;
