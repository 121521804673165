import React from "react";
import Styles from "./Styles.module.css";
import { AttentionSeeker } from "react-awesome-reveal";

function AdvertBanner() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.serviceContainer}>
        {Data.map((item, index) => (
          <div className={Styles.serviceCard} key={index}>
            <img src={item.image} alt="ShamiriVeg" />
            <AttentionSeeker>
              <h1>{item.title}</h1>
            </AttentionSeeker>
            <h4>{item.days ? item.days : ""}</h4>
            <h4>{item.description}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdvertBanner;

const Data = [
  {
    image: "/home/delivery.png",
    title: "Delivery",
    description: "Same Day Delivery",
  },
  {
    image: "/home/promotions.png",
    title: "Promotions",
    description: "Everyday Low Prices",
  },
  {
    image: "/home/time.png",
    title: "Working Hours",
    days: "Monday-Saturday",
    description: "8:00-7:00pm",
  },
  {
    image: "/home/payment.png",
    title: "Payment",
    description: "MPESA, AIRTEL MONEY or CARD",
  },
];
