import React from "react";
import { Link } from "react-router-dom";

import "./Styles.css";

function Products({ProductsData}) {
  
  return (
    <div className="main">
      <div className="products-container">
        {ProductsData.map((category) => (
          <Link
            to={`/category/${category.categoryid}`}
            key={category.categoryid}
          >
            <div className="product-card">
              <img src={category.categoryImage} alt={category.categoryname} />
              <h3>{category.categoryname}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Products;
