import React from "react";
import { Link } from "react-router-dom";
import "./Styles.css";

function Footer() {
  const footerData = require("./footerdata.json");
  return (
    <div className="mainContainer">
      <div className="category-container">
        {footerData.map((category, indexMain) => (
          <div className="category-card" key={indexMain}>
            <h3>{category.categoryname}</h3>
            {category.Links.map((link, index) => (
              <div key={index}>
                <Link to={link.link} key={category.categoryid}>
                  <li>{link.link}</li>{" "}
                </Link>
              </div>
            ))}
            <div>
              <Link to="/ShamiriVeg Pitch Decks.pdf" target="_blank">
                <li key="pitch">Resources</li>
              </Link>
            </div>
            <div style={{ display: "none" }}>
              <Link to="/Shamiriveg_logo_high_res.png" target="_blank">
                <li key="pitch">Resources</li>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Footer;
