import React from "react";
import Slider from "../hooks/slider/Slider";
import Styles from "./StylesB.module.css";
import { OutdoorGrill } from "@mui/icons-material";

function RecipeBlogAd() {
  function generateRandomNumber(min, max) {
    const range = max - min + 1;
    return Math.floor(Math.random() * range) + min;
  }

  const itemId = generateRandomNumber(1, 3);

  return (
    <div className={Styles.main}>
      
      <div className={Styles.productsContainer}>
        <div className={Styles.productCard}>
        <h1>Recepies Corner</h1>
          <Slider SliderData={images} />
        </div>
        <div className={Styles.productCard}>
          <h3>👨‍🍳{data.Chef}</h3>
          <h3>{data.title}</h3>
          <p>{data.description}</p>
          {data.steps.map((step, index) => (
            <li key={index}>
              <OutdoorGrill style={{ paddingRight: "2rem", color:"var(--blendGreenColor)" }} /> {step}
            </li>
          ))}
             <button className={Styles.infobtn}>More Recipies</button>
        </div>
      </div>
    </div>
  );
}

export default RecipeBlogAd;

const images = [
  { itemId: 1, link: "/images/recipe1.jpg" },
  { itemId: 2, link: "/images/recipe2.jpg" },
  { itemId: 3, link: "/images/recipe3.jpg" },
];

const data = {
  image: [
    { itemId: 1, link: "/images/recipe1.jpg" },
    { itemId: 2, link: "/images/recipe2.jpg" },
    { itemId: 3, link: "/images/recipe3.jpg" },
  ],
  Chef: "Chefs Pick",
  title: "East Africans Best Plain Fried Kales (Sukuma Wiki) & Spinach",
  description:
    "Greens make up one of the popular side staple  dishes in East African (EA) Homes. 80% of EA families consume fresh organic greens everyday. The most popular greens include: Sukumawiki, Spinach, Terere, Cabbage, Managu and Malenge. Served as an accompaniment,  these greens can be taken with literally all forms of starchy foods from: Bread, Ugali, Chapati, rice e.t.c.  This Recipe takes you through cooking greens in less than 6 Minutes, ",
  steps: [
    "Clean the Sukuma Wiki/Spinach by washing throughly to remove soil and dust particles",
    "Separate the leaves from stems",
    "Chop into small chewable pieces",
    " Put 1 tea spoon of cooking oil in the pan",
    "Add chopped pieces of onions, a pinch of salt and stir",
    "heat for a minute",
    "Add the chopped vegetables",
    "Cover the cooking pan and let it steam for 4 minutes",
    "**** 🥬🥬🥬Enjoy !!!!🥬🥬🥬 ****",
  ],
};
